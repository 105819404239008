export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
};

/** Autogenerated input type of AddEntityFromContentToCollection */
export type AddEntityFromContentToCollectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Content ID */
  contentId: Scalars['ID'];
  /** Collection ID */
  id: Scalars['ID'];
};

/** Autogenerated return type of AddEntityFromContentToCollection */
export type AddEntityFromContentToCollectionPayload = {
  __typename?: 'AddEntityFromContentToCollectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  collection: Collection;
  content: Content;
  entity: Entity;
};

/** Autogenerated input type of AddEntityToCollection */
export type AddEntityToCollectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  entity: EntityInput;
  /** Collection ID */
  id: Scalars['ID'];
};

/** Autogenerated return type of AddEntityToCollection */
export type AddEntityToCollectionPayload = {
  __typename?: 'AddEntityToCollectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  collection: Collection;
  content: Content;
  entity: Entity;
};

/** Autogenerated input type of AddToCollection */
export type AddToCollectionInput = {
  /** Upload an attachment directly */
  attachment?: InputMaybe<AttachmentInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Collection ID */
  id: Scalars['ID'];
  /** Upload an image directly */
  image?: InputMaybe<ImageInput>;
  metadata?: InputMaybe<Scalars['JSON']>;
  /** URL or plain text */
  value?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of AddToCollection */
export type AddToCollectionPayload = {
  __typename?: 'AddToCollectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  collection: Collection;
  content: Content;
  entity: Entity;
};

/** Autogenerated input type of AddToCollections */
export type AddToCollectionsInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Collection IDs */
  ids: Array<Scalars['ID']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  /** URL or plain text */
  value: Scalars['String'];
};

/** Autogenerated return type of AddToCollections */
export type AddToCollectionsPayload = {
  __typename?: 'AddToCollectionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  collections: Array<Collection>;
  contents: Array<Content>;
  entity: Entity;
};

export type Attachment = {
  __typename?: 'Attachment';
  contentType: Scalars['String'];
  createdAt: Scalars['String'];
  fileSize?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  toString: Scalars['String'];
  updatedAt: Scalars['String'];
  url: Scalars['String'];
};


export type AttachmentCreatedAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};


export type AttachmentFileSizeArgs = {
  precision?: InputMaybe<Scalars['Int']>;
};


export type AttachmentToStringArgs = {
  from?: InputMaybe<TruncateDirection>;
  length?: InputMaybe<Scalars['Int']>;
};


export type AttachmentUpdatedAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};

/** Input needed to create an Attachment */
export type AttachmentInput = {
  /** Filesize in bytes */
  fileContentLength: Scalars['Int'];
  /** Content-type (MIME) of file */
  fileContentType: Scalars['String'];
  /** Name of file */
  fileName: Scalars['String'];
  /** URL to file */
  url: Scalars['String'];
};

export type Collection = {
  __typename?: 'Collection';
  collection: Collection;
  content: Content;
  contents: Array<Content>;
  counts: CollectionCounts;
  createdAt: Scalars['String'];
  href: Scalars['String'];
  id: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  metadata: Scalars['JSON'];
  name: Scalars['String'];
  sample: Array<Content>;
  slug: Scalars['String'];
  title: Scalars['String'];
  toString: Scalars['String'];
  updatedAt: Scalars['String'];
  value?: Maybe<Scalars['String']>;
  within: Array<Collection>;
};


export type CollectionCollectionArgs = {
  id: Scalars['ID'];
};


export type CollectionContentArgs = {
  id: Scalars['ID'];
};


export type CollectionContentsArgs = {
  entityType?: InputMaybe<EntityTypes>;
  metadata?: InputMaybe<Scalars['JSON']>;
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ContentsSort>;
};


export type CollectionCreatedAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};


export type CollectionHrefArgs = {
  absolute?: InputMaybe<Scalars['Boolean']>;
};


export type CollectionSampleArgs = {
  amount?: InputMaybe<Scalars['Int']>;
};


export type CollectionToStringArgs = {
  from?: InputMaybe<TruncateDirection>;
  length?: InputMaybe<Scalars['Int']>;
};


export type CollectionUpdatedAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};


export type CollectionValueArgs = {
  key?: InputMaybe<Scalars['String']>;
};


export type CollectionWithinArgs = {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
};

export type CollectionCounts = {
  __typename?: 'CollectionCounts';
  contents: Scalars['Int'];
};

export type Content = {
  __typename?: 'Content';
  collection: Collection;
  createdAt: Scalars['String'];
  entity: Entity;
  href: Scalars['String'];
  id: Scalars['Int'];
  metadata: Scalars['JSON'];
  next?: Maybe<Content>;
  position: Scalars['Int'];
  previous?: Maybe<Content>;
  updatedAt: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};


export type ContentCreatedAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};


export type ContentHrefArgs = {
  absolute?: InputMaybe<Scalars['Boolean']>;
};


export type ContentUpdatedAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};


export type ContentValueArgs = {
  key?: InputMaybe<Scalars['String']>;
};

export enum ContentsSort {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  PositionAsc = 'POSITION_ASC',
  PositionDesc = 'POSITION_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

/** Autogenerated input type of CreateCollection */
export type CreateCollectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

/** Autogenerated return type of CreateCollection */
export type CreateCollectionPayload = {
  __typename?: 'CreateCollectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  collection: Collection;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['String'];
  plans: Array<Plan>;
  subscriptions: Array<Subscription>;
};

/** Autogenerated input type of DeleteCollection */
export type DeleteCollectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of DeleteCollection */
export type DeleteCollectionPayload = {
  __typename?: 'DeleteCollectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  me: User;
};

export type Entity = Attachment | Collection | Image | Link | Text;

/** Attributes for finding an Entity */
export type EntityInput = {
  /** ID of Entity */
  id: Scalars['ID'];
  /** Kind of Entity */
  type: EntityTypes;
};

export enum EntityTypes {
  Attachment = 'ATTACHMENT',
  Collection = 'COLLECTION',
  Image = 'IMAGE',
  Link = 'LINK',
  Text = 'TEXT'
}

export type Image = {
  __typename?: 'Image';
  createdAt: Scalars['String'];
  height: Scalars['Int'];
  id: Scalars['Int'];
  name: Scalars['String'];
  resized: ResizedImage;
  sourceUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  toString: Scalars['String'];
  updatedAt: Scalars['String'];
  url: Scalars['String'];
  width: Scalars['Int'];
};


export type ImageCreatedAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};


export type ImageResizedArgs = {
  blur?: InputMaybe<Scalars['Int']>;
  fit?: InputMaybe<ResizedImageFit>;
  height?: InputMaybe<Scalars['Int']>;
  quality?: InputMaybe<Scalars['Int']>;
  scale?: InputMaybe<Scalars['Float']>;
  sharpen?: InputMaybe<Scalars['Int']>;
  width?: InputMaybe<Scalars['Int']>;
};


export type ImageToStringArgs = {
  from?: InputMaybe<TruncateDirection>;
  length?: InputMaybe<Scalars['Int']>;
};


export type ImageUpdatedAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};

/** Input needed to create an Image */
export type ImageInput = {
  /** Filesize in bytes */
  fileContentLength: Scalars['Int'];
  /** Content-type (MIME) of file */
  fileContentType: Scalars['String'];
  /** Name of file */
  fileName: Scalars['String'];
  /** URL to image file */
  url: Scalars['String'];
};

export type Link = {
  __typename?: 'Link';
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  toString: Scalars['String'];
  updatedAt: Scalars['String'];
  url: Scalars['String'];
};


export type LinkCreatedAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};


export type LinkToStringArgs = {
  from?: InputMaybe<TruncateDirection>;
  length?: InputMaybe<Scalars['Int']>;
};


export type LinkUpdatedAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated input type of Login */
export type LoginInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  username: Scalars['String'];
};

/** Autogenerated return type of Login */
export type LoginPayload = {
  __typename?: 'LoginPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  jwt: Scalars['String'];
  user: User;
};

export type Mutation = {
  __typename?: 'Mutation';
  addEntityFromContentToCollection?: Maybe<AddEntityFromContentToCollectionPayload>;
  addEntityToCollection?: Maybe<AddEntityToCollectionPayload>;
  addToCollection?: Maybe<AddToCollectionPayload>;
  addToCollections?: Maybe<AddToCollectionsPayload>;
  createCollection?: Maybe<CreateCollectionPayload>;
  deleteCollection?: Maybe<DeleteCollectionPayload>;
  login?: Maybe<LoginPayload>;
  publishCollection?: Maybe<PublishCollectionPayload>;
  reactivateProductSubscription?: Maybe<ReactivateProductSubscriptionPayload>;
  register?: Maybe<RegisterPayload>;
  removeFromCollection?: Maybe<RemoveFromCollectionPayload>;
  repositionCollectionContent?: Maybe<RepositionCollectionContentPayload>;
  subscribeToProduct?: Maybe<SubscribeToProductPayload>;
  unpublishCollection?: Maybe<UnpublishCollectionPayload>;
  unsubscribeFromProduct?: Maybe<UnsubscribeFromProductPayload>;
  updateCollection?: Maybe<UpdateCollectionPayload>;
  updateContent?: Maybe<UpdateContentPayload>;
  updateEntity?: Maybe<UpdateEntityPayload>;
};


export type MutationAddEntityFromContentToCollectionArgs = {
  input: AddEntityFromContentToCollectionInput;
};


export type MutationAddEntityToCollectionArgs = {
  input: AddEntityToCollectionInput;
};


export type MutationAddToCollectionArgs = {
  input: AddToCollectionInput;
};


export type MutationAddToCollectionsArgs = {
  input: AddToCollectionsInput;
};


export type MutationCreateCollectionArgs = {
  input: CreateCollectionInput;
};


export type MutationDeleteCollectionArgs = {
  input: DeleteCollectionInput;
};


export type MutationLoginArgs = {
  input: LoginInput;
};


export type MutationPublishCollectionArgs = {
  input: PublishCollectionInput;
};


export type MutationReactivateProductSubscriptionArgs = {
  input: ReactivateProductSubscriptionInput;
};


export type MutationRegisterArgs = {
  input: RegisterInput;
};


export type MutationRemoveFromCollectionArgs = {
  input: RemoveFromCollectionInput;
};


export type MutationRepositionCollectionContentArgs = {
  input: RepositionCollectionContentInput;
};


export type MutationSubscribeToProductArgs = {
  input: SubscribeToProductInput;
};


export type MutationUnpublishCollectionArgs = {
  input: UnpublishCollectionInput;
};


export type MutationUnsubscribeFromProductArgs = {
  input: UnsubscribeFromProductInput;
};


export type MutationUpdateCollectionArgs = {
  input: UpdateCollectionInput;
};


export type MutationUpdateContentArgs = {
  input: UpdateContentInput;
};


export type MutationUpdateEntityArgs = {
  input: UpdateEntityInput;
};

export type Plan = {
  __typename?: 'Plan';
  amount: Scalars['String'];
  id: Scalars['String'];
  interval: PlanInterval;
};


export type PlanAmountArgs = {
  delimiter?: InputMaybe<Scalars['String']>;
  precision?: InputMaybe<Scalars['Int']>;
  separator?: InputMaybe<Scalars['String']>;
  stripInsignificantZeros?: InputMaybe<Scalars['Boolean']>;
};

export enum PlanInterval {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK',
  Year = 'YEAR'
}

export enum Products {
  Gaea = 'GAEA'
}

/** Autogenerated input type of PublishCollection */
export type PublishCollectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  regenerate?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of PublishCollection */
export type PublishCollectionPayload = {
  __typename?: 'PublishCollectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  collection: Collection;
};

export type Query = {
  __typename?: 'Query';
  /** Bypass subscription check to access the current customer */
  customer: Customer;
  /** The logged in current user   */
  me: User;
};

/** Autogenerated input type of ReactivateProductSubscription */
export type ReactivateProductSubscriptionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  subscriptionId: Scalars['String'];
};

/** Autogenerated return type of ReactivateProductSubscription */
export type ReactivateProductSubscriptionPayload = {
  __typename?: 'ReactivateProductSubscriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

/** Autogenerated input type of Register */
export type RegisterInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  secret: Scalars['String'];
  username: Scalars['String'];
};

/** Autogenerated return type of Register */
export type RegisterPayload = {
  __typename?: 'RegisterPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  jwt: Scalars['String'];
  user: User;
};

/** Autogenerated input type of RemoveFromCollection */
export type RemoveFromCollectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contentId: Scalars['ID'];
};

/** Autogenerated return type of RemoveFromCollection */
export type RemoveFromCollectionPayload = {
  __typename?: 'RemoveFromCollectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  collection: Collection;
  content: Content;
};

export enum ReorderAction {
  InsertAt = 'INSERT_AT',
  MoveDown = 'MOVE_DOWN',
  MoveToBottom = 'MOVE_TO_BOTTOM',
  MoveToTop = 'MOVE_TO_TOP',
  MoveUp = 'MOVE_UP'
}

/** Autogenerated input type of RepositionCollectionContent */
export type RepositionCollectionContentInput = {
  action: ReorderAction;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contentId: Scalars['ID'];
  insertAt?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated return type of RepositionCollectionContent */
export type RepositionCollectionContentPayload = {
  __typename?: 'RepositionCollectionContentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  collection: Collection;
  content: Content;
};

export type ResizedImage = {
  __typename?: 'ResizedImage';
  height: Scalars['Int'];
  url: Scalars['String'];
  urls: RetinaImage;
  width: Scalars['Int'];
};

export enum ResizedImageFit {
  Cover = 'COVER',
  Inside = 'INSIDE'
}

export type RetinaImage = {
  __typename?: 'RetinaImage';
  _1x: Scalars['String'];
  _2x: Scalars['String'];
  _3x: Scalars['String'];
};

/** Autogenerated input type of SubscribeToProduct */
export type SubscribeToProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  paymentMethodId: Scalars['String'];
  priceId: Scalars['String'];
  product: Products;
};

/** Autogenerated return type of SubscribeToProduct */
export type SubscribeToProductPayload = {
  __typename?: 'SubscribeToProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

export type Subscription = {
  __typename?: 'Subscription';
  cancelAtPeriodEnd: Scalars['Boolean'];
  currentPeriodEndAt: Scalars['String'];
  currentPeriodStartAt: Scalars['String'];
  id: Scalars['String'];
  plan: Plan;
};


export type SubscriptionCurrentPeriodEndAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};


export type SubscriptionCurrentPeriodStartAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};

export type Text = {
  __typename?: 'Text';
  body: Scalars['String'];
  createdAt: Scalars['String'];
  id: Scalars['Int'];
  length: Scalars['Int'];
  name: Scalars['String'];
  toString: Scalars['String'];
  updatedAt: Scalars['String'];
};


export type TextCreatedAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};


export type TextToStringArgs = {
  from?: InputMaybe<TruncateDirection>;
  length?: InputMaybe<Scalars['Int']>;
};


export type TextUpdatedAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};

export enum TruncateDirection {
  Center = 'CENTER',
  Head = 'HEAD',
  Tail = 'TAIL'
}

/** Autogenerated input type of UnpublishCollection */
export type UnpublishCollectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
};

/** Autogenerated return type of UnpublishCollection */
export type UnpublishCollectionPayload = {
  __typename?: 'UnpublishCollectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  collection: Collection;
};

/** Autogenerated input type of UnsubscribeFromProduct */
export type UnsubscribeFromProductInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  subscriptionId: Scalars['String'];
};

/** Autogenerated return type of UnsubscribeFromProduct */
export type UnsubscribeFromProductPayload = {
  __typename?: 'UnsubscribeFromProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  user: User;
};

/** Autogenerated input type of UpdateCollection */
export type UpdateCollectionInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata?: InputMaybe<Scalars['JSON']>;
  replace?: InputMaybe<Scalars['Boolean']>;
  title?: InputMaybe<Scalars['String']>;
};

/** Autogenerated return type of UpdateCollection */
export type UpdateCollectionPayload = {
  __typename?: 'UpdateCollectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  collection: Collection;
};

/** Autogenerated input type of UpdateContent */
export type UpdateContentInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  metadata?: InputMaybe<Scalars['JSON']>;
  replace?: InputMaybe<Scalars['Boolean']>;
};

/** Autogenerated return type of UpdateContent */
export type UpdateContentPayload = {
  __typename?: 'UpdateContentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  content: Content;
};

/** Autogenerated input type of UpdateEntity */
export type UpdateEntityInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  type: EntityTypes;
  /** URL or plain text */
  value: Scalars['String'];
};

/** Autogenerated return type of UpdateEntity */
export type UpdateEntityPayload = {
  __typename?: 'UpdateEntityPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entity: Entity;
};

/** Input needed to create an Upload */
export type UploadInput = {
  /** File name */
  name: Scalars['String'];
  /** File MIME type */
  type: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  collection: Collection;
  collections: Array<Collection>;
  content: Content;
  contents: Array<Content>;
  counts: UserCounts;
  createdAt: Scalars['String'];
  customer: Customer;
  email: Scalars['String'];
  id: Scalars['Int'];
  presignedUploadUrls: Array<Scalars['String']>;
  sample: Array<Content>;
  services: Array<Scalars['String']>;
  slug: Scalars['String'];
  updatedAt: Scalars['String'];
  username: Scalars['String'];
};


export type UserCollectionArgs = {
  id: Scalars['ID'];
};


export type UserCollectionsArgs = {
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};


export type UserContentArgs = {
  id: Scalars['ID'];
};


export type UserContentsArgs = {
  metadata?: InputMaybe<Scalars['JSON']>;
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<ContentsSort>;
};


export type UserCreatedAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};


export type UserPresignedUploadUrlsArgs = {
  uploads: Array<UploadInput>;
};


export type UserSampleArgs = {
  amount?: InputMaybe<Scalars['Int']>;
};


export type UserUpdatedAtArgs = {
  format?: InputMaybe<Scalars['String']>;
  relative?: InputMaybe<Scalars['Boolean']>;
};

export type UserCounts = {
  __typename?: 'UserCounts';
  collections: Scalars['Int'];
  contents: Scalars['Int'];
};

export type AddToCollectionMutationVariables = Exact<{
  id: Scalars['ID'];
  value?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<ImageInput>;
  attachment?: InputMaybe<AttachmentInput>;
}>;


export type AddToCollectionMutation = { __typename?: 'Mutation', addToCollection?: { __typename?: 'AddToCollectionPayload', collection: { __typename?: 'Collection', id: number }, content: { __typename?: 'Content', id: number } } | null };

export type AddToCollectionExtendedSearchQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type AddToCollectionExtendedSearchQuery = { __typename?: 'Query', filtered: { __typename?: 'User', id: number, collections: Array<{ __typename?: 'Collection', id: number, slug: string, name: string }> } };

export type CollectionContentCollectionFragment = { __typename?: 'Collection', id: number, counts: { __typename?: 'CollectionCounts', contents: number } };

export type CollectionContentContentFragment = { __typename?: 'Content', id: number, position: number, entity: { __typename: 'Attachment', id: number, url: string, fileSize?: string | null, contentType: string, label: string } | { __typename: 'Collection', slug: string, id: number, title: string, updatedAt: string, name: string, label: string, counts: { __typename?: 'CollectionCounts', contents: number } } | { __typename: 'Image', width: number, height: number, id: number, title: string, label: string, thumbnail: { __typename?: 'ResizedImage', width: number, height: number, urls: { __typename?: 'RetinaImage', _1x: string, _2x: string } } } | { __typename: 'Link', id: number, url: string, label: string, name: string } | { __typename: 'Text', id: number, label: string, blurb: string } };

export type CollectionContentContextMenuCollectionFragment = { __typename?: 'Collection', id: number, counts: { __typename?: 'CollectionCounts', contents: number } };

export type CollectionContentContextMenuContentFragment = { __typename?: 'Content', id: number, position: number, entity: { __typename: 'Attachment' } | { __typename: 'Collection' } | { __typename: 'Image' } | { __typename: 'Link' } | { __typename: 'Text' } };

type CollectionContentEntityFragment_Attachment_ = { __typename?: 'Attachment', id: number, url: string, fileSize?: string | null, contentType: string };

type CollectionContentEntityFragment_Collection_ = { __typename?: 'Collection', id: number, slug: string, title: string, updatedAt: string, name: string, counts: { __typename?: 'CollectionCounts', contents: number } };

type CollectionContentEntityFragment_Image_ = { __typename?: 'Image', id: number, title: string, thumbnail: { __typename?: 'ResizedImage', width: number, height: number, urls: { __typename?: 'RetinaImage', _1x: string, _2x: string } } };

type CollectionContentEntityFragment_Link_ = { __typename?: 'Link', id: number, url: string, name: string };

type CollectionContentEntityFragment_Text_ = { __typename?: 'Text', id: number, blurb: string };

export type CollectionContentEntityFragment = CollectionContentEntityFragment_Attachment_ | CollectionContentEntityFragment_Collection_ | CollectionContentEntityFragment_Image_ | CollectionContentEntityFragment_Link_ | CollectionContentEntityFragment_Text_;

export type CollectionContentEntityAttachmentFragment = { __typename?: 'Attachment', id: number, url: string, fileSize?: string | null, contentType: string };

export type CollectionContentEntityCollectionFragment = { __typename?: 'Collection', id: number, slug: string, title: string, updatedAt: string, name: string, counts: { __typename?: 'CollectionCounts', contents: number } };

export type CollectionContentEntityImageFragment = { __typename?: 'Image', id: number, title: string, thumbnail: { __typename?: 'ResizedImage', width: number, height: number, urls: { __typename?: 'RetinaImage', _1x: string, _2x: string } } };

export type CollectionContentEntityLinkFragment = { __typename?: 'Link', id: number, url: string, name: string };

export type CollectionContentEntityTextFragment = { __typename?: 'Text', id: number, blurb: string };

export type CollectionContentsGridFragment = { __typename?: 'Collection', id: number, contents: Array<{ __typename?: 'Content', id: number, position: number, entity: { __typename: 'Attachment', id: number, url: string, fileSize?: string | null, contentType: string, label: string } | { __typename: 'Collection', slug: string, id: number, title: string, updatedAt: string, name: string, label: string, counts: { __typename?: 'CollectionCounts', contents: number } } | { __typename: 'Image', width: number, height: number, id: number, title: string, label: string, thumbnail: { __typename?: 'ResizedImage', width: number, height: number, urls: { __typename?: 'RetinaImage', _1x: string, _2x: string } } } | { __typename: 'Link', id: number, url: string, label: string, name: string } | { __typename: 'Text', id: number, label: string, blurb: string } }>, counts: { __typename?: 'CollectionCounts', contents: number } };

export type CollectionContentsGridQueryVariables = Exact<{
  id: Scalars['ID'];
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}>;


export type CollectionContentsGridQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, collection: { __typename?: 'Collection', id: number, contents: Array<{ __typename?: 'Content', id: number, position: number, entity: { __typename: 'Attachment', id: number, url: string, fileSize?: string | null, contentType: string, label: string } | { __typename: 'Collection', slug: string, id: number, title: string, updatedAt: string, name: string, label: string, counts: { __typename?: 'CollectionCounts', contents: number } } | { __typename: 'Image', width: number, height: number, id: number, title: string, label: string, thumbnail: { __typename?: 'ResizedImage', width: number, height: number, urls: { __typename?: 'RetinaImage', _1x: string, _2x: string } } } | { __typename: 'Link', id: number, url: string, label: string, name: string } | { __typename: 'Text', id: number, label: string, blurb: string } }>, counts: { __typename?: 'CollectionCounts', contents: number } } } };

export type CollectionContentsListFragment = { __typename?: 'Collection', id: number, contents: Array<{ __typename?: 'Content', id: number, position: number, entity: { __typename: 'Attachment', id: number, url: string, fileSize?: string | null, contentType: string, name: string } | { __typename: 'Collection', id: number, title: string, slug: string, updatedAt: string, counts: { __typename?: 'CollectionCounts', contents: number } } | { __typename: 'Image', id: number, title: string, url: string, placeholder: { __typename?: 'ResizedImage', urls: { __typename?: 'RetinaImage', src: string } }, resized: { __typename?: 'ResizedImage', width: number, height: number, urls: { __typename?: 'RetinaImage', _1x: string, _2x: string } } } | { __typename: 'Link', id: number, url: string } | { __typename: 'Text', id: number, body: string, updatedAt: string, createdAt: string } }>, counts: { __typename?: 'CollectionCounts', contents: number } };

export type CollectionPreviewFragment = { __typename?: 'Collection', id: number, contents: Array<{ __typename?: 'Content', id: number, entity: { __typename: 'Attachment', id: number, contentType: string, fileSize?: string | null } | { __typename: 'Collection', id: number, title: string } | { __typename: 'Image', id: number, width: number, height: number, placeholder: { __typename?: 'ResizedImage', urls: { __typename?: 'RetinaImage', _1x: string, _2x: string } } } | { __typename: 'Link', id: number, name: string } | { __typename: 'Text', id: number, body: string } }> };

export type CollectionPreviewQueryVariables = Exact<{
  id: Scalars['ID'];
  per?: InputMaybe<Scalars['Int']>;
}>;


export type CollectionPreviewQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, collection: { __typename?: 'Collection', id: number, contents: Array<{ __typename?: 'Content', id: number, entity: { __typename: 'Attachment', id: number, contentType: string, fileSize?: string | null } | { __typename: 'Collection', id: number, title: string } | { __typename: 'Image', id: number, width: number, height: number, placeholder: { __typename?: 'ResizedImage', urls: { __typename?: 'RetinaImage', _1x: string, _2x: string } } } | { __typename: 'Link', id: number, name: string } | { __typename: 'Text', id: number, body: string } }> } } };

export type CollectionSettingsFragment = { __typename?: 'Collection', id: number, title: string, metadata: any };

export type CollectionSettingsMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
}>;


export type CollectionSettingsMutation = { __typename?: 'Mutation', updateCollection?: { __typename?: 'UpdateCollectionPayload', collection: { __typename?: 'Collection', id: number, title: string, metadata: any } } | null };

export type CollectionStubFragment = { __typename?: 'Collection', id: number, slug: string, title: string, updatedAt: string, counts: { __typename?: 'CollectionCounts', contents: number } };

export type CollectionStubListFragment = { __typename?: 'Collection', id: number, slug: string, title: string, updatedAt: string, counts: { __typename?: 'CollectionCounts', contents: number } };

type ContentEntityFragment_Attachment_ = { __typename?: 'Attachment', id: number, url: string, fileSize?: string | null, contentType: string, name: string };

type ContentEntityFragment_Collection_ = { __typename?: 'Collection', id: number, title: string, slug: string, updatedAt: string, counts: { __typename?: 'CollectionCounts', contents: number } };

type ContentEntityFragment_Image_ = { __typename?: 'Image', id: number, title: string, url: string, placeholder: { __typename?: 'ResizedImage', urls: { __typename?: 'RetinaImage', src: string } }, resized: { __typename?: 'ResizedImage', width: number, height: number, urls: { __typename?: 'RetinaImage', _1x: string, _2x: string } } };

type ContentEntityFragment_Link_ = { __typename?: 'Link', id: number, url: string };

type ContentEntityFragment_Text_ = { __typename?: 'Text', id: number, body: string, updatedAt: string, createdAt: string };

export type ContentEntityFragment = ContentEntityFragment_Attachment_ | ContentEntityFragment_Collection_ | ContentEntityFragment_Image_ | ContentEntityFragment_Link_ | ContentEntityFragment_Text_;

export type ContentEntityAttachmentFragment = { __typename?: 'Attachment', id: number, url: string, fileSize?: string | null, contentType: string, name: string };

export type ContentEntityCollectionFragment = { __typename?: 'Collection', id: number, title: string, slug: string, updatedAt: string, counts: { __typename?: 'CollectionCounts', contents: number } };

export type ContentEntityImageFragment = { __typename?: 'Image', id: number, title: string, url: string, placeholder: { __typename?: 'ResizedImage', urls: { __typename?: 'RetinaImage', src: string } }, resized: { __typename?: 'ResizedImage', width: number, height: number, urls: { __typename?: 'RetinaImage', _1x: string, _2x: string } } };

export type ContentEntityLinkFragment = { __typename?: 'Link', id: number, url: string };

export type ContentEntityTextFragment = { __typename?: 'Text', id: number, body: string, updatedAt: string, createdAt: string };

export type UpdateContentEntityTextMutationVariables = Exact<{
  id: Scalars['ID'];
  type: EntityTypes;
  value: Scalars['String'];
}>;


export type UpdateContentEntityTextMutation = { __typename?: 'Mutation', updateEntity?: { __typename?: 'UpdateEntityPayload', entity: { __typename: 'Attachment' } | { __typename: 'Collection' } | { __typename: 'Image' } | { __typename: 'Link' } | { __typename: 'Text', id: number, body: string, updatedAt: string, createdAt: string } } | null };

type ContentEntityHeaderFragment_Attachment_ = { __typename?: 'Attachment', id: number, url: string, name: string };

type ContentEntityHeaderFragment_Collection_ = { __typename?: 'Collection', id: number, name: string, slug: string };

type ContentEntityHeaderFragment_Image_ = { __typename?: 'Image', id: number, url: string, width: number, height: number, name: string };

type ContentEntityHeaderFragment_Link_ = { __typename?: 'Link', id: number, url: string, name: string };

type ContentEntityHeaderFragment_Text_ = { __typename?: 'Text', id: number, body: string, name: string };

export type ContentEntityHeaderFragment = ContentEntityHeaderFragment_Attachment_ | ContentEntityHeaderFragment_Collection_ | ContentEntityHeaderFragment_Image_ | ContentEntityHeaderFragment_Link_ | ContentEntityHeaderFragment_Text_;

export type ContentEntityHeaderAttachmentFragment = { __typename?: 'Attachment', id: number, url: string, name: string };

export type ContentEntityHeaderCollectionFragment = { __typename?: 'Collection', id: number, name: string, slug: string };

export type ContentEntityHeaderImageFragment = { __typename?: 'Image', id: number, url: string, width: number, height: number, name: string };

export type ContentEntityHeaderLinkFragment = { __typename?: 'Link', id: number, url: string, name: string };

export type ContentEntityHeaderTextFragment = { __typename?: 'Text', id: number, body: string, name: string };

export type ContentSettingsFragment = { __typename?: 'Content', id: number, metadata: any };

export type ContentSettingsMutationVariables = Exact<{
  id: Scalars['ID'];
  metadata?: InputMaybe<Scalars['JSON']>;
}>;


export type ContentSettingsMutation = { __typename?: 'Mutation', updateContent?: { __typename?: 'UpdateContentPayload', content: { __typename?: 'Content', id: number, metadata: any } } | null };

export type CreateCollectionMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type CreateCollectionMutation = { __typename?: 'Mutation', createCollection?: { __typename?: 'CreateCollectionPayload', collection: { __typename?: 'Collection', title: string, slug: string, id: number, updatedAt: string, counts: { __typename?: 'CollectionCounts', contents: number } } } | null };

export type DeleteCollectionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCollectionMutation = { __typename?: 'Mutation', deleteCollection?: { __typename: 'DeleteCollectionPayload' } | null };

export type EmbeddedCollectionFragment = { __typename?: 'Collection', id: number, contents: Array<{ __typename?: 'Content', id: number, position: number, entity: { __typename: 'Attachment', id: number, url: string, fileSize?: string | null, contentType: string, label: string } | { __typename: 'Collection', slug: string, id: number, title: string, updatedAt: string, name: string, label: string, counts: { __typename?: 'CollectionCounts', contents: number } } | { __typename: 'Image', width: number, height: number, id: number, title: string, label: string, thumbnail: { __typename?: 'ResizedImage', width: number, height: number, urls: { __typename?: 'RetinaImage', _1x: string, _2x: string } } } | { __typename: 'Link', id: number, url: string, label: string, name: string } | { __typename: 'Text', id: number, label: string, blurb: string } }>, counts: { __typename?: 'CollectionCounts', contents: number } };

export type EmbeddedCollectionQueryVariables = Exact<{
  id: Scalars['ID'];
  per?: InputMaybe<Scalars['Int']>;
}>;


export type EmbeddedCollectionQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, collection: { __typename?: 'Collection', id: number, contents: Array<{ __typename?: 'Content', id: number, position: number, entity: { __typename: 'Attachment', id: number, url: string, fileSize?: string | null, contentType: string, label: string } | { __typename: 'Collection', slug: string, id: number, title: string, updatedAt: string, name: string, label: string, counts: { __typename?: 'CollectionCounts', contents: number } } | { __typename: 'Image', width: number, height: number, id: number, title: string, label: string, thumbnail: { __typename?: 'ResizedImage', width: number, height: number, urls: { __typename?: 'RetinaImage', _1x: string, _2x: string } } } | { __typename: 'Link', id: number, url: string, label: string, name: string } | { __typename: 'Text', id: number, label: string, blurb: string } }>, counts: { __typename?: 'CollectionCounts', contents: number } } } };

export type FilesUploaderQueryVariables = Exact<{
  uploads: Array<UploadInput> | UploadInput;
}>;


export type FilesUploaderQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, presignedUploadUrls: Array<string> } };

export type FilteredCollectionStubListQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type FilteredCollectionStubListQuery = { __typename?: 'Query', filtered: { __typename?: 'User', id: number, collections: Array<{ __typename?: 'Collection', id: number, slug: string, title: string, updatedAt: string, counts: { __typename?: 'CollectionCounts', contents: number } }> } };

export type LocusCollectionsQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type LocusCollectionsQuery = { __typename?: 'Query', filtered: { __typename?: 'User', id: number, collections: Array<{ __typename?: 'Collection', id: number, slug: string, title: string }> } };

export type PublishCollectionMutationVariables = Exact<{
  id: Scalars['ID'];
  regenerate?: InputMaybe<Scalars['Boolean']>;
}>;


export type PublishCollectionMutation = { __typename?: 'Mutation', publishCollection?: { __typename?: 'PublishCollectionPayload', collection: { __typename?: 'Collection', id: number, key?: string | null } } | null };

export type RemoveFromCollectionMutationVariables = Exact<{
  contentId: Scalars['ID'];
}>;


export type RemoveFromCollectionMutation = { __typename?: 'Mutation', removeFromCollection?: { __typename?: 'RemoveFromCollectionPayload', collection: { __typename?: 'Collection', id: number } } | null };

export type RepositionCollectionContentMutationVariables = Exact<{
  contentId: Scalars['ID'];
  action: ReorderAction;
}>;


export type RepositionCollectionContentMutation = { __typename?: 'Mutation', repositionCollectionContent?: { __typename?: 'RepositionCollectionContentPayload', collection: { __typename?: 'Collection', id: number } } | null };

export type SampleCollectionContentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SampleCollectionContentQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, slug: string, collection: { __typename?: 'Collection', id: number, contents: Array<{ __typename?: 'Content', id: number }> } } };

export type UnpublishCollectionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnpublishCollectionMutation = { __typename?: 'Mutation', unpublishCollection?: { __typename?: 'UnpublishCollectionPayload', collection: { __typename?: 'Collection', id: number, key?: string | null } } | null };

export type AddEntityToCollectionMutationVariables = Exact<{
  id: Scalars['ID'];
  entityId: Scalars['ID'];
  entityType: EntityTypes;
}>;


export type AddEntityToCollectionMutation = { __typename?: 'Mutation', addEntityToCollection?: { __typename?: 'AddEntityToCollectionPayload', collection: { __typename?: 'Collection', id: number }, content: { __typename?: 'Content', id: number } } | null };

export type AddEntityFromContentToCollectionMutationVariables = Exact<{
  id: Scalars['ID'];
  contentId: Scalars['ID'];
}>;


export type AddEntityFromContentToCollectionMutation = { __typename?: 'Mutation', addEntityFromContentToCollection?: { __typename?: 'AddEntityFromContentToCollectionPayload', collection: { __typename?: 'Collection', id: number }, content: { __typename?: 'Content', id: number } } | null };

export type CreateCollectionToAddMutationVariables = Exact<{
  title: Scalars['String'];
}>;


export type CreateCollectionToAddMutation = { __typename?: 'Mutation', createCollection?: { __typename?: 'CreateCollectionPayload', collection: { __typename?: 'Collection', id: number, name: string } } | null };

export type AddCollectionToCollectionMutationVariables = Exact<{
  parentId: Scalars['ID'];
  childId: Scalars['ID'];
}>;


export type AddCollectionToCollectionMutation = { __typename?: 'Mutation', addEntityToCollection?: { __typename?: 'AddEntityToCollectionPayload', collection: { __typename?: 'Collection', id: number }, content: { __typename?: 'Content', id: number } } | null };

export type AccountPageQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountPageQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, slug: string, username: string, email: string, createdAt: string } };

export type AddToCollectionsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  value: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
}>;


export type AddToCollectionsMutation = { __typename?: 'Mutation', addToCollections?: { __typename?: 'AddToCollectionsPayload', collections: Array<{ __typename?: 'Collection', id: number }>, contents: Array<{ __typename?: 'Content', id: number }> } | null };

export type CapturePageCollectionsQueryVariables = Exact<{
  query?: InputMaybe<Scalars['String']>;
}>;


export type CapturePageCollectionsQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, collections: Array<{ __typename?: 'Collection', id: number, name: string, href: string }> } };

export type CollectionPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CollectionPageQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, slug: string, username: string, collection: { __typename?: 'Collection', id: number, slug: string, key?: string | null, title: string, updatedAt: string, metadata: any, counts: { __typename?: 'CollectionCounts', contents: number }, within: Array<{ __typename?: 'Collection', id: number, slug: string, title: string }> } } };

export type UpadateCollectionSettingsMutationVariables = Exact<{
  id: Scalars['ID'];
  title?: InputMaybe<Scalars['String']>;
}>;


export type UpadateCollectionSettingsMutation = { __typename?: 'Mutation', updateCollection?: { __typename?: 'UpdateCollectionPayload', collection: { __typename?: 'Collection', id: number, name: string } } | null };

export type CollectionSettingsPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CollectionSettingsPageQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, slug: string, username: string, collection: { __typename?: 'Collection', id: number, key?: string | null, slug: string, title: string } } };

export type CollectionsPageQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}>;


export type CollectionsPageQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, slug: string, username: string, counts: { __typename?: 'UserCounts', collections: number }, collections: Array<{ __typename?: 'Collection', id: number, slug: string, title: string, updatedAt: string, counts: { __typename?: 'CollectionCounts', contents: number } }> } };

export type ContentPageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ContentPageQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, slug: string, username: string, content: { __typename?: 'Content', id: number, metadata: any, collection: { __typename?: 'Collection', id: number, slug: string, title: string }, entity: { __typename?: 'Attachment', id: number, url: string, fileSize?: string | null, contentType: string, name: string } | { __typename?: 'Collection', id: number, name: string, slug: string, title: string, updatedAt: string, counts: { __typename?: 'CollectionCounts', contents: number } } | { __typename?: 'Image', id: number, url: string, width: number, height: number, title: string, name: string, placeholder: { __typename?: 'ResizedImage', urls: { __typename?: 'RetinaImage', src: string } }, resized: { __typename?: 'ResizedImage', width: number, height: number, urls: { __typename?: 'RetinaImage', _1x: string, _2x: string } } } | { __typename?: 'Link', id: number, url: string, name: string } | { __typename?: 'Text', id: number, body: string, updatedAt: string, createdAt: string, name: string }, next?: { __typename?: 'Content', id: number } | null, previous?: { __typename?: 'Content', id: number } | null } } };

export type HomePageQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  per?: InputMaybe<Scalars['Int']>;
}>;


export type HomePageQuery = { __typename?: 'Query', me: { __typename?: 'User', id: number, slug: string, username: string, counts: { __typename?: 'UserCounts', collections: number }, collections: Array<{ __typename?: 'Collection', id: number, slug: string, title: string, updatedAt: string, counts: { __typename?: 'CollectionCounts', contents: number } }>, contents: Array<{ __typename?: 'Content', id: number, position: number, entity: { __typename: 'Attachment', id: number, url: string, fileSize?: string | null, contentType: string, label: string } | { __typename: 'Collection', slug: string, id: number, title: string, updatedAt: string, name: string, label: string, counts: { __typename?: 'CollectionCounts', contents: number } } | { __typename: 'Image', width: number, height: number, id: number, title: string, label: string, thumbnail: { __typename?: 'ResizedImage', width: number, height: number, urls: { __typename?: 'RetinaImage', _1x: string, _2x: string } } } | { __typename: 'Link', id: number, url: string, label: string, name: string } | { __typename: 'Text', id: number, label: string, blurb: string } }> } };

export type LoginPageMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginPageMutation = { __typename?: 'Mutation', login?: { __typename?: 'LoginPayload', jwt: string, user: { __typename?: 'User', id: number, slug: string } } | null };

export type RegisterPageMutationVariables = Exact<{
  secret: Scalars['String'];
  username: Scalars['String'];
  password: Scalars['String'];
  passwordConfirmation: Scalars['String'];
  email: Scalars['String'];
}>;


export type RegisterPageMutation = { __typename?: 'Mutation', register?: { __typename?: 'RegisterPayload', jwt: string, user: { __typename?: 'User', id: number, slug: string } } | null };

export type SubscribePageFragment = { __typename?: 'User', customer: { __typename?: 'Customer', id: string, subscriptions: Array<{ __typename?: 'Subscription', id: string, currentPeriodEndAt: string, cancelAtPeriodEnd: boolean }>, plans: Array<{ __typename?: 'Plan', id: string, interval: PlanInterval, amount: string }> } };

export type SubscribePagePlanFragment = { __typename?: 'Plan', id: string, interval: PlanInterval, amount: string };

export type SubscribePageQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscribePageQuery = { __typename?: 'Query', me: { __typename?: 'User', customer: { __typename?: 'Customer', id: string, subscriptions: Array<{ __typename?: 'Subscription', id: string, currentPeriodEndAt: string, cancelAtPeriodEnd: boolean }>, plans: Array<{ __typename?: 'Plan', id: string, interval: PlanInterval, amount: string }> } } };

export type SubscribeMutationVariables = Exact<{
  priceId: Scalars['String'];
  paymentMethodId: Scalars['String'];
}>;


export type SubscribeMutation = { __typename?: 'Mutation', subscribeToProduct?: { __typename?: 'SubscribeToProductPayload', user: { __typename?: 'User', customer: { __typename?: 'Customer', id: string, subscriptions: Array<{ __typename?: 'Subscription', id: string, currentPeriodEndAt: string, cancelAtPeriodEnd: boolean }>, plans: Array<{ __typename?: 'Plan', id: string, interval: PlanInterval, amount: string }> } } } | null };

export type UnsubscribeMutationVariables = Exact<{
  subscriptionId: Scalars['String'];
}>;


export type UnsubscribeMutation = { __typename?: 'Mutation', unsubscribeFromProduct?: { __typename?: 'UnsubscribeFromProductPayload', user: { __typename?: 'User', customer: { __typename?: 'Customer', id: string, subscriptions: Array<{ __typename?: 'Subscription', id: string, currentPeriodEndAt: string, cancelAtPeriodEnd: boolean }>, plans: Array<{ __typename?: 'Plan', id: string, interval: PlanInterval, amount: string }> } } } | null };

export type ReactivateMutationVariables = Exact<{
  subscriptionId: Scalars['String'];
}>;


export type ReactivateMutation = { __typename?: 'Mutation', reactivateProductSubscription?: { __typename?: 'ReactivateProductSubscriptionPayload', user: { __typename?: 'User', customer: { __typename?: 'Customer', id: string, subscriptions: Array<{ __typename?: 'Subscription', id: string, currentPeriodEndAt: string, cancelAtPeriodEnd: boolean }>, plans: Array<{ __typename?: 'Plan', id: string, interval: PlanInterval, amount: string }> } } } | null };


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Entity": [
      "Attachment",
      "Collection",
      "Image",
      "Link",
      "Text"
    ]
  }
};
      export default result;
    